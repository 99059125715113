import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import AlertDialog from "../components/AlertDialog";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import getSubject from "../helpers/httpRequests/getSubject";
import updateReportPeriod from "../helpers/httpRequests/updateReportPeriod";
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import SubjectCard from "../components/SubjectCard";

export default function SelectSubject() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  appContext.subject = undefined;
  appContext.classroom.reportPeriod = appContext.classroom.reportPeriod ?? "T1";

  // Setup state
  const [reportPeriod, setReportPeriod] = useState(appContext.classroom.reportPeriod ?? "T1");
  const [error, setError] = useState(undefined);
  const [sending, setSending] = useState(false);
  const [comingSoonMessage, setComingSoonMessage] = useState(undefined);


	//====================================================================
	// Handle change to the reporting period
	//====================================================================
	const handleReportPeriodChange = async (event) => {
    await updateReportPeriod(appContext, event.target.value);
    appContext.classroom.reportPeriod = event.target.value;
    setReportPeriod(event.target.value);
	};

  //====================================================================
  // Handle selection the subject
  //====================================================================
  async function handleSelect(subject) {
    if (!sending) {
      setSending(true);

      if ((subject.comingSoon === true) && (appContext.educator.isTrusted !== true)) {
        setComingSoonMessage("This subject is coming soon.   Please check back regularly.");
      } else {

        // Fetch the full definition for the selected subject
        appContext.subject = await getSubject(appContext.classroom.curriculum.name, subject.name);

        navigate("/selectStudent");
      }

      setSending(false);
    }
  }


  return (
    <PageWrapper 
      title="Select Subject"
      leftButton="Classrooms"
      leftIcon={<ArrowBackIcon />}
      onLeftClick={() => navigate("/selectClassroom", {replace: true})}
      showClassroom
      showComments
    >

      <div style={{backgroundColor: "#ffffff"}}>
        <div style={{padding: "0px 20px"}}>
          <Typography variant="h6" sx={{marginLeft: "20px", marginRight: "20px", marginBottom: 0}}>
            Select the subject that you would like to prepare report comments for.  Make sure you
            select the correct term that you are reporting on.
          </Typography>
          <br/>
          <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
            <div style={{marginBottom: "10px"}}>
              <FormControl>
                 <RadioGroup
                  row
                  name="reportPeriod"
                  value={reportPeriod}
                  onChange={handleReportPeriodChange}
                >
                  {appContext.classroom.curriculum.reportPeriods.map((reportPeriod, index) => (
                    <FormControlLabel key={index} value={reportPeriod.name} control={<Radio />} label={reportPeriod.label} />
                  ))}
                </RadioGroup>
              </FormControl>
              <br/>
            </div>
          </div>
        </div>

        <div>
          {appContext.classroom.subjectSummaries.map((subject, index) => (
            <div key={index} style={{padding: "0px 20px"}}>
              <SubjectCard subject={subject} subjectClick={async () => await handleSelect(subject)} />
              <br/>
            </div>
          ))}
        </div>
      </div>

      <AlertDialog title="Subject Error" message={error} severity="error" onClose={() => setError(undefined)}/>
      <AlertDialog title="Coming Soon" message={comingSoonMessage} severity="info" onClose={() => setComingSoonMessage(undefined)}/>
        
    </PageWrapper>
  );
}