import React from "react";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PageWrapper from "../components/PageWrapper";
import createCheckoutSession from "../helpers/httpRequests/createCheckoutSession";


const tiers = [
  {
    title: "One Term",
    product: "oneTerm",
    price: "25",
    description: [
      "240 Comments for use in the Term 1 report (Winter 2025)",
      "Unused comments expire on Mar 15th, 2025",
      "Won't auto-renew"
    ],
    buttonText: "Purchase One term",
    buttonVariant: "outlined",
  },
  {
    title: "2024/2025 School Year",
    product: "schoolYear",
    subheader: "Best Value",
    price: "40",
    description: [
      "240 Comments for each of the T1 and T2 report periods of 2024/2025 School Year",
      "Unused comments for a report period expire at end of that report period",
      "All comments expire on June 30th, 2025",
      "Won't auto-renew"
    ],
    buttonText: "Purchase School Year",
    buttonVariant: "contained",
  },
];

export default function PricingContent() {
  const navigate = useNavigate();
  const plusTax = " + tax";

  async function purchaseProduct(product) {
    const res = await createCheckoutSession(product);
    window.location.href = res.url;
  }

  return (
    <PageWrapper
      title="Pricing"
      leftButton="Back"
      leftIcon={<ArrowBackIcon />}
      onLeftClick={() => navigate(-1)}
    >
      <React.Fragment>
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }} />
        <CssBaseline />

        {/* Hero unit */}
        <Container disableGutters maxWidth="md" component="main" sx={{ pt: 4, pb: 6, pl: 2, pr: 2 }}>
          <Typography variant="h5" color="text.secondary" component="p">
            You can purchase a plan that covers your reporting for the current term, or for the 
            entire school year.
          </Typography>
          <br/>
          <Typography variant="h5" color="text.secondary" component="p">
            All payments are one-time charge only.
          </Typography>
          <br/>
          <Typography variant="h5" color="text.primary" component="p">
            *** Special offer:  Right now when you purchase the "School Year" package, we
            throw in the comments for the current term reports for FREE.
          </Typography>
        </Container>
        {/* End hero unit */}

        <Container maxWidth="md" component="main">
          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map((tier) => (
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={tier.title === "School Year" ? 12 : 6}
                md={6}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: "center" }}
                    action={tier.title === "School Year" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2
                      }}
                    >
                      <Typography component="h2" variant="h3" color="text.primary">
                        ${tier.price}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                         {plusTax}
                      </Typography>
                    </Box>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth variant={tier.buttonVariant} onClick={async () => await purchaseProduct(tier.product)}>
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </React.Fragment>
    </PageWrapper>
  );
}