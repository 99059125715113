import React from 'react';
import {useNavigate} from "react-router-dom";
import { Typography, Box } from '@mui/material';

export default function PricingInfo() {
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '40px'}}>
        <Typography variant="h5" fontWeight={700} sx={{color: 'black'}}>
          Pricing
        </Typography>
      </Box>

      <div style={{display: "flex", justifyContent: "center" , marginTop: "20px"}}>
        <Typography variant="h5" align="center">After your 5 free comments, pay just $25 for up to 240 comments for the Winter T1 Report (comments expire March 15th, 2025).  Comments are available for learning skills, Math, English, French, Arts, Science, Social Studies, PhysEd</Typography>
      </div>
      <div style={{display: "flex", justifyContent: "center" , marginTop: "20px"}}>
        <Typography variant="h5" align="center">Or, pay just $40 for the remaining 2024/2025 School Year, for up to 240 comments per remaining report period (T1, T2) (all comments expire June 30th, 2025)</Typography>
      </div>
    </Box>
  );
};